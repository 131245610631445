<template>
    <div class="orders">
        <bxs-top-nav>Commesse</bxs-top-nav>

        <bxs-data-lazy
        v-model="items"
        :call="() => $tfork.orders.list({ limit: 10, sort: { open_date: -1, created_at: -1 }, filters: { 'agent.ref': $tfork.auth.me.id } })">
            <section>
                <bxs-layout>
                    <bxs-text-field
                    v-model="keywords"
                    solo
                    clearable
                    name="keywords"
                    label="Cerca commessa"
                    class="mb-0"
                    @click="$router.push('/orders')">
                        <template #prepend>
                            <bxs-icon
                            name="search"
                            width="1.25em"></bxs-icon>
                        </template>
                    </bxs-text-field>

                    <!-- <bxs-stepper
                    ref="stepper"
                    v-model="month_step"
                    :items="stepper_months">
                    </bxs-stepper> -->

                    <div class="flex">
                        <bxs-chip
                        v-if="!!selected_status"
                        outlined
                        small
                        prepend-icon="filters"
                        append-icon="close"
                        class="bxs-pointer"
                        @click="selected_status = null">
                            <span class="mx-ui">{{ $filters.toHumanOrderStatus(selected_status) }}</span>
                        </bxs-chip>

                        <bxs-spacer></bxs-spacer>

                        <bxs-menu close-on-click>
                            <template #activator="{ on }">
                                <bxs-btn
                                v-on="on"
                                prepend-icon="filters"
                                text>Filtri</bxs-btn>
                            </template>

                            <bxs-list>
                                <bxs-list-item color="secondary">Stato commesse</bxs-list-item>

                                <bxs-list-item
                                @click="selected_status = null"
                                divider>Tutte</bxs-list-item>

                                <bxs-list-item
                                v-for="(st, i) in $tfork.enums.order.status"
                                :key="i"
                                :append-icon="selected_status === st.value ? 'check' : null"
                                :text-color="selected_status === st.value ? 'info' : null"
                                divider
                                @click="selected_status = st.value">{{ st.text }}</bxs-list-item>
                            </bxs-list>
                        </bxs-menu>
                    </div>
                </bxs-layout>
            </section>

            <!--  -->
            <section>
                <bxs-layout>
                    <ul v-if="items.docs.length > 0">
                        <li
                        v-for="order in items.docs"
                        :key="order.id">
                            <order-row :item="order" />
                            <div class="bxs-divider my-ui"></div>
                        </li>
                    </ul>

                    <div
                    v-else
                    class="text-center">
                        <p class="text-500 mb-1">Nessuna commessa trovata <span v-if="!!keywords">per "{{ keywords }}"</span></p>
                        <small class="text-mute">Prova a effettuare nuovamente la ricerca.</small>
                    </div>

                    <div
                    v-if="!items.docs.length && !keywords && !selected_status"
                    class="text-center mt-ui py-ui">
                        <div class="mb-6">
                            <bxs-icon
                            name="file-earmark-text-fill"
                            width="3rem"
                            height="3rem"></bxs-icon>
                        </div>

                        <p>Nessuna commessa da visualizzare al momento</p>

                        <bxs-btn
                        prepend-icon="file-earmark-text-fill"
                        to="/orders/new/edit"
                        class="mt-ui">Nuova commessa</bxs-btn>
                    </div>
                </bxs-layout>
            </section>
        </bxs-data-lazy>
    </div>
</template>

<script>
import OrderRow from '@/components/project/OrderRow.vue'

export default {
    name: 'orders',
    components: {
        'order-row': OrderRow
    },
    data () {
        return {
            busy: false,
            loading: false,
            items: {
                docs: [],
                pagination: {
                    page: 1
                }
            },
            stepper_months: [
                { text: 'Gen' },
                { text: 'Feb' },
                { text: 'Mar' },
                { text: 'Apr' },
                { text: 'Mag' },
                { text: 'Giu' },
                { text: 'Lug' },
                { text: 'Ago' },
                { text: 'Set' },
                { text: 'Ott' },
                { text: 'Nov' },
                { text: 'Dic' }
            ],
            selected_status: null,
            keywords: null
        }
    },
    mounted () {
        this.$nextTick(this.play)
    },
    watch: {
        keywords () {
            this.getDocs()
        },
        selected_status () {
            this.getDocs()
        }
    },
    methods: {
        play () {
            window.addEventListener('scroll', this.handleScroll)
        },
        stop () {
            window.removeEventListener('scroll', this.handleScroll)
        },
        handleScroll () {
            if ((window.innerHeight + window.scrollY) >= (document.body.scrollHeight - (window.innerHeight / 2))) {
                if (this.items.pagination.has_next_page && !this.busy) {
                    this.getDocs(this.items.pagination.page += 1)
                }
            }
        },
        async getDocs (page = 1) {
            this.busy = true
            this.loading = true
            const filters = {
                'agent.ref': this.$tfork.auth.me.id
            }

            if (this.keywords) {
                filters.keywords = this.keywords
            }

            if (this.selected_status) {
                filters.status = '=|' + this.selected_status
            }

            try {
                const {
                    docs,
                    pagination
                } = await this.$tfork.orders.list({
                    limit: 10,
                    page: page,
                    sort: { open_date: -1, created_at: -1 },
                    filters: filters
                })

                if (page === 1) this.items.docs = docs
                else this.items.docs.push(...docs)

                this.items.pagination = pagination
            } catch (err) {
            } finally {
                this.busy = false
                this.loading = false
            }
        }
    },
    beforeUnmount () {
        this.stop()
    }
}
</script>