<template>
    <div
    class="order-product-row flex nowrap"
    @click="select">
        <div>
            <bxs-figure
            v-if="mode === 'view'"
            :src="item.product.img ? item.product.img.url : null"
            width="60"
            ratio="1" />

            <div
            v-else
            style="display: inline-flex; width: 60px; height: 60px; align-items: center; justify-content: center;">
                <bxs-btn
                icon
                color="error"
                class="mx-auto"
                @click="$emit('remove', item.id)">
                    <bxs-icon name="close"></bxs-icon>
                </bxs-btn>
            </div>
        </div>

        <div class="flex-1 text-truncate pl-ui">
            <h6 class="mb-1">{{ item.product.name }} - <span>TG {{ item.product.size }}</span></h6>
            <!-- - IVA {{ row.vat }} -->
            <p class="mb-2"><span class="text-mute">{{ item.product.sku }}</span> - Qt: {{ item.quantity }}</p>
            <p class="text-info text-600" v-html="$filters.toHumanPrice(item.price_net_revenue)"></p>
        </div>

        <div>
            <bxs-icon
            v-if="draggable && mode === 'edit'"
            width="20"
            height="20"
            name="grip-vertical"></bxs-icon>

            <bxs-btn
            v-else
            icon>
                <bxs-icon name="chevron-right"></bxs-icon>
            </bxs-btn>
        </div>
    </div>
</template>

<script>
export default {
    name: 'order_edit_row',
    props: {
        item: {
            type: Object,
            required: false,
            default: () => {}
        },
        mode: {
            type: String,
            required: false,
            default: 'view'
        },
        draggable: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        'select',
        'remove'
    ],
    methods: {
        select () {
            this.$emit('select', JSON.parse(JSON.stringify(this.item)))
        }
    }
}
</script>