import moment from 'moment'
import { getCurrentInstance } from 'vue'

moment.locale('it')

const formatter = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR'
    // minimumFractionDigits: 2,
    // maximumFractionDigits: 4,
    // minimumSignificantDigits: 1,
    // maximumSignificantDigits: 4
})

const d = {
    toHumanDate(value, f = 'L') {
        return value && moment(value).isValid() ? moment(value).format(f) : (value || '--')
    },
    toHumanCalendar(value) {
        return value ? moment(value).calendar() : (value || '--')
    },
    toHumanOrderStatus(value) {
        const app = getCurrentInstance()
        const api = app.appContext.config.globalProperties.$tfork
        let v = value

        if (!api || !api.enums || !api.enums.order) return value

        const status = api.enums.order.status.find(i => i.value === value)
        if (status) v = status.text

        return v
    },
    toHumanPaymentMethod (value) {
        const app = getCurrentInstance()
        const api = app.appContext.config.globalProperties.$tfork
        let v = value

        if (!api || !api.enums || !api.enums.payment_method) return value

        const status = api.enums.payment_method.types.find(i => i.value === value)
        if (status) v = status.text

        return v
    },
    isMajorOfNow(value) {
        if (!value || !moment(value).isValid()) return value
        return moment(value).diff(moment()) >= 0
    },
    toHumanPrice(value) {
        const v = formatter.format(value).replace('€', '')
        return `<span class="bxs-currency-format"><span>€</span><span>${v}</span></span>`
    },
    truncate(v, l = 115) {
        if (!v) return v
        return v.substring(0, l) + '...'
    },
    highlight(v, k) {
        if (!v || !k) return v
        return v.replace(k, '<span class="text-highlight">' + k + '</span>')
    }
}

export default d