<template>
    <bxs-drawer
    v-model="local_on"
    persistent
    title="Aggiungi taglie articolo">
        <bxs-loader v-if="local_loading"></bxs-loader>

        <div
        v-else-if="!local_loading && !!local_product"
        :key="local_on">
            <div class="mb-ui">
                <h5>{{ local_product.name.it }}</h5>
                <p class="text-mute">#{{ local_product.sku }}</p>
            </div>

            <div
            v-if="local_product.imgs.length > 0"
            class="bxs-scrollable bxs-scrollable-x mb-ui">
                <div
                v-for="(img, i) in local_product.imgs"
                :key="i"
                class="bxs-scrollable-x-item mr-ui">
                    <bxs-figure
                    :src="img.url ? img.url.replace('/uploads/commons/', '/uploads/commons/thumbs/360/') : null"
                    width="100px"
                    ratio="1" />
                </div>
            </div>

            <bxs-figure
            v-else
            width="100px"
            ratio="1"
            class="mb-6" />

            <!--  -->
            <div>
                <div class="mb-ui">
                    <bxs-title>Quantità</bxs-title>
                    <small>Compila le quantità e salva per aggiungerle</small>
                </div>

                <bxs-list>
                    <bxs-list-item
                    v-for="prod_var in local_product.variant_products"
                    :key="prod_var.id"
                    two-line>
                        <p>TG {{ prod_var.size.it }}</p>

                        <p
                        v-html="$filters.toHumanPrice(prod_var.prices.net)"
                        class="text-mute"></p>

                        <!-- <bxs-text-field
                        :modelValue="prod_var.prices.net"
                        currency
                        solo
                        readonly
                        disabled
                        label="Prezzo"
                        style="max-width: 100px;" /> -->

                        <bxs-spacer></bxs-spacer>

                        <template #action>
                            <bxs-text-field
                            v-model="local_quantities[prod_var.id]"
                            :number="{ valueScaling: 'precision' }"
                            label="Quantità"
                            solo
                            hide-details
                            clearable
                            style="max-width: 160px;" />
                        </template>
                    </bxs-list-item>
                </bxs-list>
            </div>
        </div>

        <template #actions>
            <slot
            name="actions"
            :item="local_product" />
        </template>
    </bxs-drawer>
</template>

<script>
export default {
    name: 'order_edit_row_edit_drawer',
    props: {
        on: {
            type: Boolean,
            required: false,
            default: false
        },
        product: {
            type: Object,
            required: false,
            default: null
        },
        quantities: {
            type: Object,
            required: false,
            default: () => {}
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        'update:on',
        'update:quantities',
        'change'
    ],
    data () {
        return {
            local_on: this.on,
            local_product: this.product,
            local_quantities: this.quantities,
            local_loading: this.loading
        }
    },
    watch: {
        on (newVal) {
            this.local_on = newVal
        },
        local_on (newVal) {
            this.$emit('update:on', newVal)
        },
        //
        product (newVal) {
            this.local_product = newVal
        },
        //
        local_quantities: {
            deep: true,
            handler (newVal) {
                this.$emit('update:quantities', newVal)
            }
        },
        quantities (newVal) {
            this.local_quantities = newVal
        },
        //
        loading (newVal) {
            this.local_loading = newVal
        }
    }
}
</script>