<template>
    <div
    v-if="!preloaded"
    class="text-center">
        <bxs-loader label="Verifica in corso" />
    </div>

    <div
    v-else-if="preloaded && error"
    class="text-center pa-ui">
        <pre>
            <code>{{ error }}</code>
        </pre>
    </div>

    <div
    v-else
    class="order-view">
        <bxs-top-nav hide-undo>
            <span>Commessa Nr. <span>{{ item ? item.number_id : null }}</span></span>
        </bxs-top-nav>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <section class="tertiary">
            <bxs-layout>
                <div class="text-center">
                    <p><small>{{ $filters.toHumanDate(item.created_at) }}</small></p>
                    <h6 class="mb-2">Commessa nr. {{ item.number_id }}</h6>

                    <bxs-chip
                    :color="status_color"
                    dark
                    class="text-uppercase">{{ $filters.toHumanOrderStatus(item.status) }}</bxs-chip>
                </div>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <bxs-row style="height: 80px;">
                    <bxs-col class="text-center flex column justify-center bxs-truncate">
                        <p class="text-uppercase mb-2">Cliente</p>
                        <p class="text-500">{{ item.customer.business_name || '--' }}</p>
                    </bxs-col>

                    <bxs-col class="text-center">
                        <div class="bxs-divider bxs-divider-vertical"></div>
                    </bxs-col>

                    <bxs-col class="text-center flex column justify-center bxs-truncate">
                        <p class="text-uppercase mb-2">Data</p>
                        <p class="text-500">{{ $filters.toHumanDate(item.created_at) }}</p>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            address
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <section class="secondary">
            <bxs-layout>
                <p>Cliente</p>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <div class="item-spacer">
                    <p>P.IVA</p>
                    <p>{{ item.customer.vat_code }}</p>
                </div>

                <div class="item-spacer">
                    <p>Codice SDI</p>
                    <p>{{ item.customer.sdi }}</p>
                </div>

                <div class="item-spacer">
                    <p>PEC</p>
                    <p>{{ item.customer.pec || '--' }}</p>
                </div>

                <div class="item-spacer">
                    <p>Sede legale</p>
                    <p>{{ item.customer.billing_address.address }}, {{ item.customer.billing_address.zipcode }} {{ item.customer.billing_address.city }} <span v-if="item.customer.billing_address.province">({{ item.customer.billing_address.province }})</span></p>
                </div>
            </bxs-layout>
        </section>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            products
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <section class="secondary">
            <bxs-layout>
                <p>Articoli</p>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <ul>
                    <li
                    v-for="(row, i) in item.rows"
                    :key="i">
                        <row-item :item="row" />

                        <div
                        v-if="i < item.rows.length - 1"
                        class="bxs-divider my-ui"></div>
                    </li>
                </ul>
            </bxs-layout>
        </section>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            delivery
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <section class="secondary">
            <bxs-layout>
                <p>Inforrmazioni sulla consegna</p>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <div class="flex nowrap">
                    <p>{{ item.delivery.method || '--' }}</p>
                    <bxs-spacer></bxs-spacer>
                    <p v-html="$filters.toHumanPrice(item.delivery.gross)"></p>
                </div>

                <p>{{ item.delivery.note }}</p>
            </bxs-layout>
        </section>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            payment
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <section class="secondary">
            <bxs-layout>
                <p>Inforrmazioni sul pagamento</p>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <div class="flex nowrap">
                    <p>{{  $filters.toHumanPaymentMethod(item.payment.method) }}</p>
                    <bxs-spacer></bxs-spacer>
                    <p>Sconto {{ item.payment.discount }}%</p>
                </div>

                <p>{{ item.payment.note }}</p>
            </bxs-layout>
        </section>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            cash
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <section class="secondary">
            <bxs-layout>
                <p>Importi</p>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <p class="flex justify-between text-500">
                    <span>Imponibile</span>
                    <span v-html="$filters.toHumanPrice(item.cash.total_net)"></span>
                </p>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <p class="flex justify-between text-500">
                    <span>IVA 22%</span>
                    <span v-html="$filters.toHumanPrice((item.cash.total_gross - item.delivery.gross) - item.cash.total_net)"></span>
                </p>
            </bxs-layout>
        </section>

        <section class="secondary">
            <bxs-layout>
                <p class="flex justify-between text-info text-500">
                    <span>Totale</span>
                    <span v-html="$filters.toHumanPrice(item.cash.total_gross)"></span>
                </p>
            </bxs-layout>
        </section>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <section v-if="item.status === 'under_evaluation'">
            <bxs-layout>
                <div class="text-center">
                    <!-- <bxs-btn
                    width="160"
                    color="error"
                    class="mr-1"
                    @click="changeStatus('canceled')">Rifiuta</bxs-btn> -->

                    <bxs-btn
                    width="160"
                    color="success"
                    class="ml-1"
                    @click="changeStatus('confirmed')">Conferma</bxs-btn>
                </div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
import OrderEditRow from '@/components/project/OrderEditRecapRow.vue'

export default {
    name: 'order_customer_view',
    components: {
        'row-item': OrderEditRow
    },
    data () {
        return {
            preloaded: false,
            item: null,
            error: null,
            customer_auth_code: null,
            confirm_loading: false,
            confirm_error: null
        }
    },
    computed: {
        status_color () {
            if (this.item.status === 'draft') return 'info'
            else if (this.item.status === 'under_evaluation') return 'warn'
            else if (this.item.status === 'confirmed') return 'success'
            else if (this.item.status === 'canceled') return 'mute'
            return null
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        async start () {
            this.preloaded = false
            this.error = null

            try {
                this.item = await this.$tfork.get('/v1/g/o/' + this.$route.params.order_id + '/c/' + this.$route.params.customer_authcode)
                // await this.changeStatus('confirmed')
            } catch (err) {
                this.error = err
            } finally {
                this.preloaded = true
            }
        },
        async changeStatus (val) {
            this.$store.commit('set_is_loading', true)
            this.confirm_loading = true
            const human_status = val === 'confirmed' ? 'confermata' : 'annullata'

            try {
                await this.$tfork.patch('/v1/g/o/' + this.$route.params.order_id + '/c/' + this.$route.params.customer_authcode, {
                    status: val
                })
                this.item = await this.$tfork.get('/v1/g/o/' + this.$route.params.order_id + '/c/' + this.$route.params.customer_authcode)

                this.$toast.success('Commessa ' + human_status)
            } catch (err) {
                this.confirm_error = err
            } finally {
                this.confirm_loading = false
                this.$store.commit('set_is_loading', false)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.item-spacer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 0.25rem;

    > p {
        &:first-child {
            color: var(--color-mute);
        }
    }
}
</style>