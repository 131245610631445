<template>
    <div class="signin-view">
        <section>
            <bxs-layout class="text-center">
                <div class="mb-12">
                    <h1>Accedi</h1>
                </div>

                <div>
                    <bxs-form
                    ref="form"
                    class="mb-12"
                    @submit="submit">
                        <bxs-text-field
                        v-model="email"
                        name="email"
                        required
                        label="Email"
                        class="mb-layout" />

                        <bxs-text-field
                        v-model="password"
                        required
                        label="Password"
                        type="password"
                        name="password"
                        class="mb-layout">
                            <template #append="{ on, value }">
                                <bxs-btn
                                v-on="on"
                                small>{{ !value ? 'show' : 'hide' }}</bxs-btn>
                            </template>
                        </bxs-text-field>

                        <!-- <bxs-btn
                        text
                        block
                        to="/password/recovery">Hai dimenticato la password? Recupera</bxs-btn> -->

                        <bxs-btn
                        block
                        :disabled="loading"
                        :loading="loading"
                        @click="$refs.form.submit()">Accedi</bxs-btn>
                    </bxs-form>
                </div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'signin',
    data () {
        return {
            email: null,
            password: null,
            error: null,
            loading: false
        }
    },
    methods: {
        async submit () {
            this.loading = true
            this.$store.commit('set_is_loading', true)

            try {
                await this.$tfork.auth.signInWithEmailAndPassword(this.email, this.password)
                this.$router.go()
            } catch (err) {
                this.loading = false
                throw new Error(err.message || err.code)
            } finally {
                this.$store.commit('set_is_loading', false)
            }
        }
    }
}
</script>