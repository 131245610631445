<template>
    <section>
        <bxs-layout>
            <div class="flex align-center mb-ui">
                <bxs-title class="mb-0">Ultime commesse</bxs-title>

                <bxs-spacer></bxs-spacer>

                <router-link
                to="/orders"
                class="flex nowrap align-center text-info">
                    <span>Vedi tutte</span>
                    <bxs-icon
                    name="chevron-right"
                    class="ml-2"></bxs-icon>
                </router-link>
            </div>

            <bxs-data-lazy :call="() => $tfork.orders.list({ limit: 6, pagination: false, sort: { open_date: -1, created_at: -1 }, filters: { 'agent.ref': $tfork.auth.me.id } })">
                <template #data="{ data }">
                    <ul v-if="data.length > 0">
                        <li
                        v-for="order in data"
                        :key="order.id">
                            <order-row :item="order" />
                            <div class="bxs-divider my-ui"></div>
                        </li>
                    </ul>

                    <div
                    v-else
                    class="text-center text-mute mt-ui py-ui">
                        <p>Nessuna commessa da visualizzare al momento</p>
                    </div>
                </template>
            </bxs-data-lazy>
        </bxs-layout>
    </section>
</template>

<script>
import OrderRow from '@/components/project/OrderRow.vue'

export default {
    name: 'orders_section',
    components: {
        'order-row': OrderRow
    }
}
</script>