<template>
    <div class="me">
        <bxs-top-nav>Profilo</bxs-top-nav>

        <section>
            <bxs-layout>
                <div>
                    <h4>Salve, {{ $tfork.me.name }}</h4>
                    <p class="text-mute"><small>{{ $tfork.me.email }}</small></p>
                </div>
            </bxs-layout>
        </section>

        <!--  -->
        <section>
            <bxs-layout>
                <div>
                    <bxs-title>Statistiche</bxs-title>
                </div>

                <bxs-data-lazy :call="() => $tfork.getStatus()">
                    <template #data="{ data }">
                        <bxs-row>
                            <bxs-col cols="6">
                                <bxs-card class="pa-ui">
                                    <h4 class="mb-1">{{ data.orders_analytics.under_evaluation_count }}</h4>
                                    <p><small>Commesse in valutazione</small></p>
                                </bxs-card>
                            </bxs-col>

                            <bxs-col cols="6">
                                <bxs-card class="pa-ui">
                                    <h4 class="mb-1">{{ data.orders_analytics.draft_count }}</h4>
                                    <p><small>Commesse in bozza</small></p>
                                </bxs-card>
                            </bxs-col>

                            <bxs-col cols="6">
                                <bxs-card class="pa-ui">
                                    <h4 class="mb-1">
                                        <span v-if="data.orders_analytics.last_order" v-html="$filters.toHumanPrice(data.orders_analytics.last_order.cash.total_net)"></span>
                                        <span v-else>--</span>
                                    </h4>
                                    <p><small>Tot. ultima commessa</small></p>
                                </bxs-card>
                            </bxs-col>

                            <bxs-col cols="6">
                                <bxs-card
                                src="/img/gradient-1.webp"
                                class="pa-ui">
                                    <h4 class="mb-1" v-html="$filters.toHumanPrice(data.orders_analytics.total_net)"></h4>
                                    <p><small>Tot. commesse</small></p>
                                </bxs-card>
                            </bxs-col>
                        </bxs-row>
                    </template>
                </bxs-data-lazy>
            </bxs-layout>
        </section>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <orders-section />

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <section>
            <bxs-layout>
                <bxs-list>
                    <bxs-list-item
                    divider
                    prepend-icon="info"
                    href="mailto:info@tfork.it">Bisogno di aiuto?</bxs-list-item>

                    <bxs-list-item
                    prepend-icon="chevron-left"
                    @click="signout">Esci</bxs-list-item>
                </bxs-list>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'me',
    data () {
        return {
            data_page: null
        }
    },
    methods: {
        async signout () {
            try {
                this.$store.commit('set_is_loading', true)
                await this.$tfork.auth.signout()
                this.$router.go()
            } catch (err) {
                throw new Error(err.message || err.code || err)
            } finally {
                this.$store.commit('set_is_loading', false)
            }
        }
    }
}
</script>