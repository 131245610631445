<template>
    <div class="home">
        <section class="mt-ui">
            <bxs-layout>
                <div>
                    <h4>Salve {{ $tfork.me.name }}</h4>
                    <p class="text-mute"><small>{{ $tfork.me.email }}</small></p>
                </div>

                <!-- <bxs-text-field
                v-model="keywords"
                solo
                clearable
                name="keywords"
                label="Cerca commesse, clienti e articoli"
                class="mb-0"
                @click="$router.push('/orders')">
                    <template #prepend>
                        <bxs-icon
                        name="search"
                        width="1.25em"></bxs-icon>
                    </template>
                </bxs-text-field> -->
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <bxs-list>
                    <bxs-list-item
                    to="/orders"
                    prepend-icon="file-earmark-text-fill"
                    append-icon="chevron-right">Cerca commessa</bxs-list-item>
                </bxs-list>

                <bxs-list>
                    <bxs-list-item
                    to="/orders/new/edit"
                    color="info"
                    prepend-icon="file-earmark-text-fill"
                    append-icon="plus">Nuova commessa</bxs-list-item>
                </bxs-list>
            </bxs-layout>
        </section>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <orders-section />
    </div>
</template>

<script>
export default {
    name: 'home',
    data () {
        return {
            keywords: null
        }
    },
    methods: {
        async handleClickNewOrder () {
            const id = await this.$tfork.orders.create({
                salesagent: this.$tfork.me.id
            })

            this.$router.push('/orders/' + id)
        }
    }
}
</script>
