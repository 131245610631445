<template>
    <div
    class="bxs-pointer"
    @click="to">
        <div class="flex nowrap justify-between align-center mb-1">
            <div class="flex align-center">
                <small class="mr-ui">{{ $filters.toHumanDate(item.created_at) }}</small>

                <small class="mr-ui">Nr. {{ item.number_id }}</small>

                <bxs-chip
                rounded
                :color="status_color"
                dark
                x-small
                class="text-uppercase">{{ $filters.toHumanOrderStatus(item.status) }}</bxs-chip>

                <bxs-icon
                v-if="item.status === 'confirm_3' || item.status === 'canceled_4'"
                text-color="success"
                class="ml-2">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                </bxs-icon>
            </div>

            <p v-html="$filters.toHumanPrice(item.cash.total_net)"></p>
        </div>

        <div class="flex nowrap justify-between align-center">
            <div class="text-truncate">
                <p>{{ item.agent.description }}</p>
            </div>

            <bxs-icon name="chevron-right"></bxs-icon>
        </div>
    </div>
</template>

<script>
export default {
    name: 'order_row',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        status_color () {
            if (this.item.status === 'draft') return 'info'
            else if (this.item.status === 'under_evaluation') return 'warn'
            else if (this.item.status === 'confirmed') return 'success'
            else if (this.item.status === 'canceled') return 'mute'
            return null
        }
    },
    methods: {
        to () {
            if (this.item.status === 'draft') {
                return this.$router.push('/orders/' + this.item.id + '/edit')
            }

            this.$router.push('/orders/' + this.item.id)
        }
    }
}
</script>