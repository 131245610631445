import axios from 'axios'
import { parse, stringify } from 'qs'

export default class HTTP {
    constructor(config = {}, emitter) {
        this.config = config
        this.emitter = emitter

        this.http = axios.create({
            baseURL: this.config.base_url,
            withCredentials: true,
            timeout: this.config.timeout,
            headers: {
                [this.config.prefix + '-requester-lang']: this.config.requester_Lang || 'it',
                'x-app-id': this.config.app_id
            },
            paramsSerializer: {
                encode: parse,
                serialize: (params) => {
                    return stringify(params, {
                        arrayFormat: 'repeat',
                        indices: false,
                        allowDots: false,
                        skipNulls: true,
                        format: 'RFC1738'
                    })
                }
            },
            validateStatus: this.config.validate_status
        })

        this.interceptorsRequest()
        this.interceptorsResponse()

        return this
    }

    // interceptors

    interceptorsRequest() {
        this.http.interceptors.request.use((config) => {
            const access_token = sessionStorage.getItem(this.config.token_key) || null

            if (access_token) {
                config.headers = {
                    ...config.headers,
                    [this.config.prefix + '-access-token']: access_token
                }
            }

            return config
        }, Promise.reject)
    }

    interceptorsResponse() {
        this.http.interceptors.response.use((response) => {
            // if (this.config.prefix + '-access-token' in response.headers) {
            //     sessionStorage.setItem(this.config.token_key, response.headers[this.config.prefix + '-access-token'])
            // }

            return response
        }, async (err) => {
            const originalConfig = err.config
            const token = sessionStorage.getItem(this.config.token_key) || null
            console.log('> http interceptorsResponse', err.response, token)

            if (err.response && err.response.status === 401) {
                // refresh_token expired
                if (!token || originalConfig.url === 'v1/auths/access/refresh') {
                    this.emitter.emit('access-token-expired', err)
                    sessionStorage.removeItem(this.config.token_key)
                    return Promise.reject(err)
                }

                // access_token expired
                if (!originalConfig._retry) {
                    originalConfig._retry = true

                    try {
                        const access = await this.post('v1/auths/access/refresh', {
                            access_token: token
                        })

                        sessionStorage.setItem(this.config.token_key, access.token)
                        this.http.defaults.headers.common[this.config.prefix + '-access-token'] = access.token
                        this.emitter.emit('access-token-refreshed', access.token)
                        return this.http(originalConfig)
                    } catch (_err) {
                        this.emitter.emit('access-token-expired', _err)
                        sessionStorage.removeItem(this.config.token_key)
                        return Promise.reject(err)
                    }
                }
            }

            this.emitter.emit('http-error', err.response && err.response.data ? err.response.data : err.response)

            return Promise.reject(err)
        })
    }

    // publics

    options(resource, params) {
        return new Promise((resolve, reject) => {
            this.http.options(resource, { params: params }).then((res) => {
                this.emitter.emit('http-options-success', res.data)
                return resolve(res.data)
            }).catch((err) => {
                this.emitter.emit('http-get-error', err.response && err.response.data ? err.response.data : err.response)
                return reject(err.response && err.response.data ? err.response.data : err.response)
            })
        })
    }

    get(resource, params) {
        return new Promise((resolve, reject) => {
            this.http.get(resource, { params: params }).then((res) => {
                this.emitter.emit('http-get-success', res.data)
                return resolve(res.data)
            }).catch((err) => {
                this.emitter.emit('http-get-error', err.response && err.response.data ? err.response.data : err.response)
                return reject(err.response && err.response.data ? err.response.data : err.response)
            })
        })
    }

    post(resource, data, headers) {
        return new Promise((resolve, reject) => {
            this.http.post(resource, data, { headers: headers }).then((res) => {
                this.emitter.emit('http-post-success', res)
                return resolve(res.data)
            }).catch((err) => {
                this.emitter.emit('http-post-error', err.response && err.response.data ? err.response.data : err.response)
                return reject(err.response && err.response.data ? err.response.data : err.response)
            })
        })
    }

    put(resource, data) {
        return new Promise((resolve, reject) => {
            this.http.put(resource, data)
                .then((res) => {
                    this.emitter.emit('http-put-success', res)
                    return resolve(res.data)
                })
                .catch((err) => {
                    this.emitter.emit('http-post-error', err.response && err.response.data ? err.response.data : err.response)
                    reject(err.response && err.response.data ? err.response.data : err.response)
                })
        })
    }

    patch(resource, data) {
        return new Promise((resolve, reject) => {
            this.http.patch(resource, data)
                .then((res) => {
                    this.emitter.emit('http-patch-success', res)
                    return resolve(res.data)
                })
                .catch(err => {
                    this.emitter.emit('http-patch-error', err.response && err.response.data ? err.response.data : err.response)
                    return reject(err.response && err.response.data ? err.response.data : err.response)
                })
        })
    }

    delete(resource, data, headers) {
        return new Promise((resolve, reject) => {
            this.http.delete(resource, {
                data: data,
                headers: headers
            })
                .then(res => {
                    this.emitter.emit('http-delete-success', res)
                    return resolve(res.data)
                })
                .catch((err) => {
                    this.emitter.emit('http-delete-error', err.response && err.response.data ? err.response.data : err.response)
                    return reject(err.response && err.response.data ? err.response.data : err.response)
                })
        })
    }
}
