<template>
    <div class="home-landing-view">
        <bxs-top-nav>Ops!</bxs-top-nav>

        <section class="py-12">
            <bxs-layout class="text-center">
                <h1>Error 404</h1>
                <p>Pagina non trovata o in costruzione</p>
                <p class="my-3">...</p>
                <bxs-btn to="/">Riparti</bxs-btn>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'error_404'
}
</script>