<template>
    <header class="navframe">
        <bxs-toolbar>
            <logo />

            <bxs-spacer></bxs-spacer>

            <!-- <bxs-chip
            v-if="!!$tfork.auth.me"
            color="success"
            light
            small
            to="/me">
                <span>{{ $tfork.me.name }}</span>
            </bxs-chip> -->

            <!-- <bxs-btn
            v-if="!!$tfork.auth.me"
            fab
            class="ml-ui"
            @click="signout">
                <bxs-icon>
                    <path d="M8.538 1.02a.5.5 0 1 0-.076.998 6 6 0 1 1-6.445 6.444.5.5 0 0 0-.997.076A7 7 0 1 0 8.538 1.02Z"/>
                    <path d="M7.096 7.828a.5.5 0 0 0 .707-.707L2.707 2.025h2.768a.5.5 0 1 0 0-1H1.5a.5.5 0 0 0-.5.5V5.5a.5.5 0 0 0 1 0V2.732l5.096 5.096Z"/>
                </bxs-icon>
            </bxs-btn> -->

            <bxs-menu v-if="!!$tfork.auth.me">
                <bxs-list>
                    <!-- <bxs-list-item>Segnala</bxs-list-item> -->
                    <bxs-list-item @click="signout">Logout</bxs-list-item>
                </bxs-list>
            </bxs-menu>
        </bxs-toolbar>
    </header>
</template>

<script>
export default {
    name: 'bxs-navbar',
    methods: {
        async signout () {
            await this.$tfork.auth.signout()
            this.$router.go()
        }
    }
}
</script>

<style lang="scss" scoped>
.navframe {
    position: relative;
    width: 100%;
    min-height: var(--navbar-height);
    background-color: var(--navbar-background-color);
    display: flex;
    align-items: center;
    margin-bottom: var(--navbar-margin-bottom);
}
</style>