<template>
    <div class="ui-components-view">
        <bxs-top-nav>UI Comps</bxs-top-nav>

        <!-- --------------------------------------------------------------------
            colors
        -------------------------------------------------------------------- -->
        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <p><i>Colors</i></p>
                    <div class="bxs-divider mt-2"></div>
                </div>

                <div class="light">
                    <div class="mb-ui">
                        <div class="ui-comp-box primary">
                            <small>primary</small>
                        </div>

                        <div class="ui-comp-box secondary">
                            <small>secondary</small>
                        </div>

                        <div class="ui-comp-box tertiary">
                            <small>tertiary</small>
                        </div>

                        <div class="ui-comp-box fourth">
                            <small>fourth</small>
                        </div>

                        <div class="ui-comp-box highlight">
                            <small>highlight</small>
                        </div>

                        <div class="ui-comp-box mute">
                            <small>mute</small>
                        </div>

                        <div class="ui-comp-box disabled">
                            <small>disabled</small>
                        </div>
                    </div>

                    <div class="mb-ui">
                        <div class="ui-comp-box info">
                            <small>info</small>
                        </div>

                        <div class="ui-comp-box success">
                            <small>success</small>
                        </div>

                        <div class="ui-comp-box error">
                            <small>error</small>
                        </div>

                        <div class="ui-comp-box warn">
                            <small>warn</small>
                        </div>
                    </div>

                    <div class="mb-ui">
                        <div
                        v-for="n in 12"
                        :key="n"
                        :class="['ui-comp-box', 'greyscale-' + n]">
                            <small>greyscale-{{ n }}</small>
                        </div>
                    </div>

                    <div class="mb-ui">
                        <div class="ui-comp-box fb">
                            <small>fb</small>
                        </div>

                        <div class="ui-comp-box yt">
                            <small>yt</small>
                        </div>

                        <div class="ui-comp-box ig">
                            <small>ig</small>
                        </div>

                        <div class="ui-comp-box tw">
                            <small>tw</small>
                        </div>

                        <div class="ui-comp-box li">
                            <small>li</small>
                        </div>
                    </div>
                </div>
            </bxs-layout>
        </section>

        <!-- --------------------------------------------------------------------
            typo
        -------------------------------------------------------------------- -->

        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <p><i>Typo</i></p>
                    <div class="bxs-divider mt-2"></div>
                </div>

                <div class="mb-ui">
                    <h1>Lorem ipsum</h1>
                    <h2>Lorem ipsum</h2>
                    <h3>Lorem ipsum</h3>
                    <h4>Lorem ipsum</h4>
                    <h5>Lorem ipsum</h5>
                    <h6>Lorem ipsum</h6>
                </div>

                <div>
                    <p class="mb-2">Lorem ipsum dolor, sit amet consectetur adipisicing elit. <router-link to="/">Quo commodi</router-link>, nam voluptate quisquam fuga inventore!</p>
                    <p class="mb-2">Lorem ipsum dolor sit amet, <b>consectetur adipisicing elit</b>. Dolore quos magni dolorum voluptatum fugiat blanditiis cupiditate quam.</p>
                    <p class="mb-2 text-mute">Lorem ipsum <u>dolor sit amet</u> consectetur adipisicing elit. Quae totam facere, reprehenderit distinctio laboriosam dolorem, harum <i>debitis asperiores beatae dicta fugit tempore</i>.</p>
                    <small>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis doloribus libero, blanditiis dolore eos repudiandae distinctio nemo explicabo corporis id eligendi rem aliquid adipisci quos sed porro quaerat labore quia.</small>
                </div>
            </bxs-layout>
        </section>

        <!-- --------------------------------------------------------------------
            actions
        -------------------------------------------------------------------- -->

        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <p><i>Actions</i></p>
                    <div class="bxs-divider mt-2"></div>
                </div>

                <div class="mb-ui">
                    <router-link to="/" class="mr-6">Your int link</router-link>
                    <a href="https://leila-bologna.it">Your _blank link</a>
                </div>

                <div class="mb-ui">
                    <bxs-btn class="mr-2 mb-2">Your base button</bxs-btn>

                    <bxs-btn
                    color="tertiary"
                    light
                    class="mr-2 mb-2">Your base colored button</bxs-btn>

                    <bxs-btn
                    color="secondary"
                    class="mr-2 mb-2">Your base colored button</bxs-btn>

                    <bxs-btn text class="mr-2 mb-2">Your text button</bxs-btn>
                    <bxs-btn
                    pill
                    append-icon="close"
                    class="mr-2 mb-2">Your Pill button</bxs-btn>
                    <bxs-btn outlined class="mr-2 mb-2">Your Outlined button</bxs-btn>

                    <bxs-btn icon class="mr-2 mb-2">
                        <bxs-icon name="envelope"></bxs-icon>
                    </bxs-btn>

                    <bxs-btn icon color="info" class="mr-2 mb-2">
                        <bxs-icon name="envelope"></bxs-icon>
                    </bxs-btn>

                    <bxs-btn fab class="mr-2 mb-2">
                        <bxs-icon name="envelope"></bxs-icon>
                    </bxs-btn>

                    <bxs-btn
                    fab
                    color="info"
                    dark
                    class="mr-2 mb-2">
                        <bxs-icon name="envelope"></bxs-icon>
                    </bxs-btn>

                    <bxs-btn
                    append-icon="envelope"
                    prepend-icon="envelope"
                    class="mr-2 mb-2">Your icon button</bxs-btn>

                    <bxs-btn
                    outlined
                    append-icon="envelope"
                    prepend-icon="envelope"
                    class="mr-2 mb-2">Your outlined icon button</bxs-btn>

                    <bxs-btn
                    block
                    between
                    append-icon="chevron-right"
                    class="mr-2">Your block base button</bxs-btn>
                </div>

                <div>
                    <bxs-alert class="mb-ui">Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe quia ipsa voluptas praesentium libero, voluptatum blanditiis nobis optio laudantium amet facere quod impedit, quisquam atque ipsum minima reiciendis, ipsam quis.</bxs-alert>
                    <bxs-alert type="warn" class="mb-ui">Lorem ipsum dolor sit amet consectetur adipisicing elit.</bxs-alert>
                    <bxs-alert type="error" class="mb-ui">Lorem ipsum dolor sit amet consectetur adipisicing elit.</bxs-alert>
                </div>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <p><i>UI</i></p>
                    <div class="bxs-divider mt-2"></div>
                </div>

                <!-- --------------------------------------------------------------------------
                    modal
                -------------------------------------------------------------------------- -->
                <bxs-modal
                v-model="modal_on"
                class="mb-ui">
                    <template #activator="{ on }">
                        <bxs-btn v-on="on">I'm modal activator</bxs-btn>
                    </template>

                    <template #header>
                        <bxs-title>I'm persistent modal</bxs-title>
                        <small class="text-mute">Lorem ipsum dolor sit amet consectetur adipisicing elit.</small>
                    </template>

                    <div class="pa-ui">
                        <bxs-text-field
                        solo
                        name="agent.description"
                        placeholder="Nome del cliente, del negozio, altro, ..." />
                    </div>

                    <template #actions="{ on }">
                        <bxs-btn
                        v-on="on"
                        block
                        color="secondary"
                        between
                        append-icon="chevron-right">Salva e continua</bxs-btn>
                    </template>
                </bxs-modal>

                <!-- --------------------------------------------------------------------------
                    drawer
                -------------------------------------------------------------------------- -->
                <bxs-drawer
                v-model="drawer_on"
                title="I'm drawer"
                class="mb-ui">
                    <template #activator="{ on }">
                        <bxs-btn v-on="on">I'm drawer activator</bxs-btn>
                    </template>

                    <template #header>
                        <div class="pa-ui">
                            <bxs-title :level="5">I'm drawer</bxs-title>
                            <small class="text-mute">Lorem ipsum dolor sit amet consectetur adipisicing elit.</small>
                        </div>

                        <bxs-divider></bxs-divider>
                    </template>

                    <bxs-title>Lorem ipsum</bxs-title>

                    <bxs-list>
                        <bxs-list-item
                        v-for="n in 25"
                        :key="n"
                        divider>Lorem ipsum {{ n }}</bxs-list-item>
                    </bxs-list>

                    <template #actions>
                        <bxs-btn>Hi!</bxs-btn>
                    </template>
                </bxs-drawer>

                <!-- --------------------------------------------------------------------------
                    menu
                -------------------------------------------------------------------------- -->
                <bxs-menu
                v-model="menu_on"
                class="mb-ui">
                    <template #header>
                        <bxs-title :level="5">I'm menu</bxs-title>
                        <small class="text-mute">Lorem ipsum dolor sit amet consectetur adipisicing elit.</small>
                    </template>

                    <!-- <bxs-list>
                        <bxs-list-item
                        divider
                        append-icon="edit">I'm option 1</bxs-list-item>
                        <bxs-list-item append-icon="close">I'm option 2</bxs-list-item>
                    </bxs-list> -->

                    <bxs-list>
                        <bxs-list-item
                        v-for="n in 25"
                        :key="n"
                        divider>Lorem ipsum {{ n }}</bxs-list-item>
                    </bxs-list>
                </bxs-menu>

                <div class="mb-ui fourth pa-ui">
                    <bxs-card class="pa-ui mb-ui">
                        <bxs-title>I'm space card</bxs-title>
                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                    </bxs-card>

                    <bxs-card class="pa-0">
                        <div class="pa-ui">I'm not space card</div>

                        <div class="bxs-divider"></div>

                        <bxs-list-item
                        to="/signin"
                        append-icon="chevron-right">
                            <p>Signin</p>
                            <small class="text-mute">List item 1</small>
                        </bxs-list-item>
                    </bxs-card>
                </div>

                <div class="mb-ui">
                    <bxs-title>Simple list</bxs-title>

                    <bxs-list>
                        <bxs-list-item to="/signin">
                            <p>Signin</p>
                            <small class="text-mute">List item 1</small>
                        </bxs-list-item>

                        <bxs-list-item
                        append-icon="chevron-right"
                        divider
                        to="/">I'm list item</bxs-list-item>

                        <bxs-list-item
                        append-icon="chevron-right"
                        divider
                        to="/">I'm list item</bxs-list-item>

                        <bxs-list-item append-icon="arrow-right">I'm list item</bxs-list-item>
                    </bxs-list>
                </div>

                <div class="mb-ui">
                    <bxs-title>Border list</bxs-title>

                    <bxs-list border>
                        <bxs-list-item
                        to="/signin">
                            <p>I'm list-item</p>
                            <small class="text-mute">Lorem, ipsum dolor sit</small>
                        </bxs-list-item>

                        <bxs-list-item
                        append-icon="chevron-right"
                        to="/">Recupero password</bxs-list-item>

                        <bxs-list-item
                        append-icon="chevron-right"
                        to="/">List item 3</bxs-list-item>
                    </bxs-list>
                </div>

                <div class="mb-ui">
                    <bxs-title>Border list 2</bxs-title>

                    <bxs-list border>
                        <bxs-list-item
                        two-line
                        divider>
                            <p>I'm list-item two-line</p>
                            <small class="text-mute">Lorem, ipsum dolor sit</small>
                        </bxs-list-item>

                        <bxs-list-item
                        to="/signin"
                        append-icon="chevron-right">Recupero password</bxs-list-item>
                    </bxs-list>
                </div>

                <div class="mb-ui">
                    <bxs-title>Collapse</bxs-title>
                    <bxs-collapse-item label="I'm collapse item 1">Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum numquam, soluta illo provident tenetur saepe. Iure, ea nulla aliquid, dignissimos hic qui explicabo officiis incidunt tenetur consequuntur eveniet. Blanditiis, eius.</bxs-collapse-item>
                    <bxs-collapse-item label="I'm collapse item 2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum numquam, soluta illo provident tenetur saepe. Iure, ea nulla aliquid, dignissimos hic qui explicabo officiis incidunt tenetur consequuntur eveniet. Blanditiis, eius.</bxs-collapse-item>
                </div>
            </bxs-layout>
        </section>

        <!-- --------------------------------------------------------------------
            form
        -------------------------------------------------------------------- -->

        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <p><i>Form</i></p>
                    <div class="bxs-divider mt-2"></div>
                </div>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <bxs-title>I'm form</bxs-title>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                </div>

                <bxs-form>
                    <bxs-textarea-field
                    solo
                    label="Lorem ipsum"
                    name="note" />

                    <bxs-text-field
                    hide-form
                    clearable
                    name="keywords"
                    label="Cosa stai cercando?">
                        <template #prepend>
                            <bxs-icon
                            name="search"
                            width="1.25em"></bxs-icon>
                        </template>
                    </bxs-text-field>

                    <bxs-switch-field
                    label="I'm switch field"
                    name="switch" />
                </bxs-form>
            </bxs-layout>
        </section>

        <!-- --------------------------------------------------------------------
            single comps
        -------------------------------------------------------------------- -->

        <!-- --------------------------------------------------------------------
            sections
        -------------------------------------------------------------------- -->
    </div>
</template>

<script>
export default {
    name: 'ui_components',
    data () {
        return {
            modal_on: false,
            menu_on: false,
            drawer_on: false
        }
    }
}
</script>