<template>
    <div class="flex nowrap">
        <!-- flex nowrap align-center -->
        <div class="flex-1">
            <!-- <bxs-figure
            :src="item.product.img ? item.product.img.url : null"
            width="50"
            ratio="1" /> -->

            <div class="text-truncate">
                <h6 class="mb-1">{{ item.product.name }} - TG {{ item.product.size }}</h6>
            </div>
        </div>

        <div class="text-right">
            <p class="mb-1">
                <small class="inline-flex align-center">
                    <span>{{ item.quantity }}</span>
                    <span class="mx-1">*</span>
                    <span v-html=" $filters.toHumanPrice(item.price_net || 0)"></span>
                </small>
            </p>

            <h6 v-html="$filters.toHumanPrice(item.price_net_revenue)"></h6>
        </div>
    </div>
</template>

<script>
export default {
    name: 'order_edit_recap_row',
    props: {
        item: {
            type: Object,
            required: true
        }
    }
}
</script>