import Resource from './BaseResource'

class Order extends Resource {
    constructor(config, tools, version, path) {
        super(config, tools, version, path)

        this.t = {
            'billing_address.address': 'Sede legale',
            business_name: 'Denominazione',
            sdi: 'Codice SDI',
            email: 'Email',
            vat_code: 'P.IVA'
        }
    }

    confirm(id, body) {
        body.status = 'under_evaluation'
        body.last_send = new Date()
        return this.tools.http[id ? 'put' : 'post'](id ? this.api + '/' + id : this.api, body)
    }
}

export default Order
