<template>
    <bxs-drawer
    v-model="local_on"
    title="Cerca e seleziona articoli">
        <template #header>
            <bxs-text-field
            v-model="keywords"
            clearable
            solo
            name="keywords"
            label="Ricerca per codice articolo">
                <template #prepend>
                    <bxs-icon
                    name="search"
                    width="1.25em"></bxs-icon>
                </template>
            </bxs-text-field>

            <bxs-data-lazy
            :call="() => $tfork.deps.list({ pagination: false, sort: { 'path.it': 1 }, select: 'name path product_count', filters: { product_count: '>|0'  } })"
            class="mt-ui px-ui">
                <template #data="{ data }">
                    <bxs-chip-group>
                        <bxs-chip
                        v-for="(c, i) in data"
                        :key="i"
                        :color="selected_categories.includes(c.id) ? 'white' : null"
                        :dark="selected_categories.includes(c.id)"
                        class="bxs-pointer"
                        @click="selectCategory(c.id)">
                            <bxs-icon
                            v-if="selected_categories.includes(c.id)"
                            name="close"
                            class="mr-2"></bxs-icon>
                            <span>{{ c.path.it.replace('.', '/') }} ({{ c.product_count }})</span>
                        </bxs-chip>
                    </bxs-chip-group>

                    <div
                    v-if="selected_categories.length > 0"
                    class="mt-2">
                        <bxs-btn
                        prepend-icon="close"
                        text
                        @click="selected_categories = []">Deseleziona categorie ({{ selected_categories.length }})</bxs-btn>
                    </div>
                </template>
            </bxs-data-lazy>
        </template>

        <!--  -->
        <div>
            <div
            v-if="local_loading"
            class="py-6">
                <bxs-loader />
            </div>

            <ul v-else-if="!local_loading && items.docs.length > 0">
                <li
                v-for="product in items.docs"
                :key="product.id"
                class="mb-ui bxs-pointer"
                @click="select(product)">
                    <bxs-row>
                        <bxs-col cols="2">
                            <bxs-figure
                            :src="product.imgs && product.imgs.length > 0 ? product.imgs[0].url.replace('/uploads/commons/', '/uploads/commons/thumbs/360/') : null"
                            ratio="1" />
                        </bxs-col>

                        <bxs-col
                        cols="8"
                        class="pl-1">
                            <h6>{{ product.name.it }}</h6>
                            <small class="text-mute">{{ product.sku }}</small>

                            <slot
                            name="item.append"
                            :item="product" />
                        </bxs-col>

                        <bxs-col
                        cols="2"
                        class="text-right">
                            <bxs-btn icon>
                                <bxs-icon name="chevron-right"></bxs-icon>
                            </bxs-btn>
                        </bxs-col>
                    </bxs-row>
                </li>
            </ul>

            <div
            v-else-if="!local_loading && !items.docs.length && (!!keywords || selected_categories.length > 0)"
            class="text-center">
                <p class="text-500 mb-1">Nessun articolo trovato <span v-if="!!keywords">per "{{ keywords }}"</span> <span v-if="selected_categories.length > 0">nella/e categorie selezionate</span></p>
                <small class="text-mute">Prova a effettuare nuovamente la ricerca selezionando un'altra categoria.</small>
            </div>
        </div>

        <template #actions>
            <div class="flex nowrap align-center justify-between">
                <bxs-btn
                icon
                :disabled="!items.pagination.has_prev_page || local_loading"
                color="mute"
                @click="getItems(items.pagination.prev_page)">
                    <bxs-icon name="chevron-left"></bxs-icon>
                </bxs-btn>

                <span class="flex-1 text-center">Pagina {{ items.pagination.page }} di {{ items.pagination.total_pages }}</span>

                <bxs-btn
                icon
                :disabled="!items.pagination.has_next_page || local_loading"
                color="mute"
                @click="getItems(items.pagination.next_page)">
                    <bxs-icon name="chevron-right"></bxs-icon>
                </bxs-btn>
            </div>
        </template>
    </bxs-drawer>
</template>

<script>
export default {
    name: 'search_products_drawer',
    props: {
        on: {
            type: Boolean,
            required: false,
            default: false
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        'update:on',
        'select'
    ],
    data () {
        return {
            local_on: this.on,
            keywords: null,
            local_loading: this.loading,
            selected_categories: [],
            items: {
               docs: [],
                pagination: {
                    page: 1
                }
            }
        }
    },
    created () {
        this.getItems()
    },
    watch: {
        on (newVal) {
            this.local_on = newVal
        },
        local_on (newVal) {
            this.$emit('update:on', newVal)
        },
        'keywords' () {
            this.getItems()
        },
        selected_categories: {
            deep: true,
            handler () {
                this.getItems()
            }
        }
    },
    methods: {
        async getItems (page = 1) {
            this.local_loading = true
            const filters = {
                is_available: true,
                is_variant: '=|false'
            }

            if (this.keywords) {
                filters.sku = this.keywords
            }

            if (this.selected_categories.length > 0) {
                filters.categories = '[]|' + this.selected_categories.join(',')
            }

            try {
                this.items = await this.$tfork.products.list({
                    limit: 40,
                    page: page,
                    spread: ['imgs'],
                    select: ['name', 'imgs', 'sku', 'prices'],
                    sort: { name: 1 },
                    filters: filters
                })
            } catch (err) {
            } finally {
                this.local_loading = false
            }
        },
        selectCategory (id) {
            if (this.selected_categories.includes(id)) {
                const i = this.selected_categories.findIndex(v => v === id)
                this.selected_categories.splice(i, 1)
            } else {
                this.selected_categories.push(id)
            }
        },
        async select (item) {
            // const product = await this.$tfork.products.doc(id, {
            //     populate: 'imgs variant_products'
            // })

            this.$emit('select', JSON.parse(JSON.stringify(item)))
        }
    }
}
</script>