<template>
    <div class="order-view">
        <bxs-top-nav>
            <span>Commessa nr. <span>{{ item ? item.number_id : null }}</span></span>

            <template #append>
                <bxs-menu icon>
                    <bxs-list>
                        <bxs-list-item
                        prepend-icon="file-earmark-text-fill"
                        to="/orders/new/edit">Nuova commessa</bxs-list-item>

                        <!-- <bxs-list-item
                        prepend-icon="copy"
                        disabled
                        @click="$tfork.duplicate()">Duplica commessa</bxs-list-item> -->

                        <!-- <bxs-list-item
                        prepend-icon="download"
                        disabled
                        @click="download">Scarica commessa</bxs-list-item> -->
                    </bxs-list>
                </bxs-menu>
            </template>
        </bxs-top-nav>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <bxs-data-lazy
        v-model="item"
        :call="() => $tfork.orders.doc($route.params.id)">
            <section class="tertiary">
                <bxs-layout>
                    <div class="text-center">
                        <p><small>{{ $filters.toHumanDate(item.created_at) }}</small></p>
                        <h6 class="mb-2">Commessa nr. {{ item.number_id }}</h6>

                        <bxs-chip
                        :color="status_color"
                        dark
                        class="text-uppercase">{{ $filters.toHumanOrderStatus(item.status) }}</bxs-chip>
                    </div>
                </bxs-layout>
            </section>

            <section>
                <bxs-layout>
                    <bxs-row style="height: 80px;">
                        <bxs-col class="text-center flex column justify-center bxs-truncate">
                            <p class="text-uppercase mb-2">Cliente</p>
                            <p class="text-500">{{ item.customer.business_name || '--' }}</p>
                        </bxs-col>

                        <bxs-col class="text-center">
                            <div class="bxs-divider bxs-divider-vertical"></div>
                        </bxs-col>

                        <bxs-col class="text-center flex column justify-center bxs-truncate">
                            <p class="text-uppercase mb-2">Data</p>
                            <p class="text-500">{{ $filters.toHumanDate(item.created_at) }}</p>
                        </bxs-col>
                    </bxs-row>
                </bxs-layout>
            </section>

            <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
                products
            ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
            <section class="secondary">
                <bxs-layout>
                    <p>Articoli</p>
                </bxs-layout>
            </section>

            <section>
                <bxs-layout>
                    <ul>
                        <li
                        v-for="(row, i) in item.rows"
                        :key="i">
                            <row-item :item="row" />

                            <div
                            v-if="i < item.rows.length - 1"
                            class="bxs-divider my-ui"></div>
                        </li>
                    </ul>
                </bxs-layout>
            </section>

            <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
                delivery
            ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
            <section class="secondary">
                <bxs-layout>
                    <p>Inforrmazioni sulla consegna</p>
                </bxs-layout>
            </section>

            <section>
                <bxs-layout>
                    <div class="flex nowrap">
                        <p>{{ item.delivery.method || '--' }}</p>
                        <bxs-spacer></bxs-spacer>
                        <p v-html="$filters.toHumanPrice(item.delivery.gross)"></p>
                    </div>

                    <p>{{ item.delivery.note }}</p>
                </bxs-layout>
            </section>

            <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
                payment
            ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
            <section class="secondary">
                <bxs-layout>
                    <p>Inforrmazioni sul pagamento</p>
                </bxs-layout>
            </section>

            <section>
                <bxs-layout>
                    <div class="flex nowrap">
                        <p>{{  $filters.toHumanPaymentMethod(item.payment.method) }}</p>
                        <bxs-spacer></bxs-spacer>
                        <p>Sconto {{ item.payment.discount }}%</p>
                    </div>

                    <p>{{ item.payment.note }}</p>
                </bxs-layout>
            </section>

            <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
                cash
            ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
            <section class="secondary">
                <bxs-layout>
                    <p>Importi</p>
                </bxs-layout>
            </section>

            <section>
                <bxs-layout>
                    <p class="flex justify-between text-500">
                        <span>Imponibile</span>
                        <span v-html="$filters.toHumanPrice(item.cash.total_net)"></span>
                    </p>
                </bxs-layout>
            </section>

            <section>
                <bxs-layout>
                    <p class="flex justify-between text-500">
                        <span>IVA 22%</span>
                        <span v-html="$filters.toHumanPrice((item.cash.total_gross - item.delivery.gross) - item.cash.total_net)"></span>
                    </p>
                </bxs-layout>
            </section>

            <section class="secondary">
                <bxs-layout>
                    <p class="flex justify-between text-info text-500">
                        <span>Totale</span>
                        <span v-html="$filters.toHumanPrice(item.cash.total_gross)"></span>
                    </p>
                </bxs-layout>
            </section>
        </bxs-data-lazy>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <!-- <bxs-bottom-nav v-if="!!$tfork.auth.me">
            <bxs-toolbar>
                <bxs-btn
                icon
                fab
                to="/">
                    <bxs-icon name="envelope"></bxs-icon>
                </bxs-btn>

                <bxs-spacer></bxs-spacer>

                <bxs-btn
                icon
                fab
                to="/">
                    <bxs-icon name="share"></bxs-icon>
                </bxs-btn>

                <bxs-spacer></bxs-spacer>

                <bxs-btn
                icon
                fab
                to="/">
                    <bxs-icon name="print"></bxs-icon>
                </bxs-btn>
            </bxs-toolbar>
        </bxs-bottom-nav> -->
    </div>
</template>

<script>
import OrderEditRow from '@/components/project/OrderEditRecapRow.vue'

export default {
    name: 'order',
    components: {
        'row-item': OrderEditRow
    },
    data () {
        return {
            item: null,
            customer_auth_code: null
        }
    },
    computed: {
        status_color () {
            if (this.item.status === 'draft') return 'info'
            else if (this.item.status === 'under_evaluation') return 'warn'
            else if (this.item.status === 'confirmed') return 'success'
            else if (this.item.status === 'canceled') return 'mute'
            return null
        }
    },
    methods: {
        download () {
        }
    }
}
</script>