<template>
    <bxs-drawer
    v-model="local_on"
    title="Cerca e seleziona cliente">
        <template #header>
            <bxs-text-field
            v-model="keywords"
            clearable
            solo
            name="keywords"
            label="Cerca o digita il nome di un nuovo cliente">
                <template #prepend>
                    <bxs-icon
                    name="search"
                    width="1.25em"></bxs-icon>
                </template>
            </bxs-text-field>
        </template>

        <div>
            <div
            v-if="loading"
            class="py-6">
                <bxs-loader />
            </div>

            <ul v-else-if="!loading && items.docs.length > 0">
                <li
                v-for="customer in items.docs"
                :key="customer.id"
                class="mb-ui bxs-pointer"
                @click="select(customer)">
                    <p class="text-uppercase">{{ customer.business_name }}</p>
                    <p class="text-mute">{{ customer.vat_code }}</p>

                    <small class="text-mute">
                        <span>{{ customer.billing_address.city }}</span>
                        <span v-if="customer.billing_address.province">({{ customer.billing_address.province }})</span>
                        <span v-if="customer.billing_address.city || customer.billing_address.province" class="mx-1">-</span>
                        <span>{{ customer.billing_address.address }}</span>
                    </small>

                    <bxs-divider class="my-ui"></bxs-divider>
                </li>
            </ul>

            <div
            v-else
            class="text-center">
                <p class="text-500 mb-1">Nessun cliente trovato <span v-if="!!keywords">per "{{ keywords }}"</span></p>
                <p class="text-mute">Prova a effettuare nuovamente la ricerca.</p>

                <div
                v-if="!!keywords"
                class="mt-ui">
                    <bxs-btn
                    block
                    text
                    color="info"
                    prepend-icon="plus"
                    @click="$emit('add:business_name', keywords)">Nuovo cliente: <b>{{ keywords }}</b></bxs-btn>
                </div>
            </div>
        </div>

        <!--  -->
        <template #actions>
            <div
            v-if="items.pagination.has_prev_page || items.pagination.has_next_page"
            class="flex nowrap align-center justify-between py-ui">
                <bxs-btn
                icon
                :disabled="!items.pagination.has_prev_page || loading"
                color="mute"
                @click="getItems(items.pagination.prev_page)">
                    <bxs-icon name="chevron-left"></bxs-icon>
                </bxs-btn>

                <small class="flex-1 text-center">Pagina {{ items.pagination.page }} di {{ items.pagination.total_pages }}</small>

                <bxs-btn
                icon
                :disabled="!items.pagination.has_next_page || loading"
                color="mute"
                @click="getItems(items.pagination.next_page)">
                    <bxs-icon name="chevron-right"></bxs-icon>
                </bxs-btn>
            </div>
        </template>
    </bxs-drawer>
</template>

<script>
export default {
    name: 'search_customers_drawer',
    props: {
        on: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        'update:on',
        'select',
        'add:business_name'
    ],
    data () {
        return {
            local_on: this.on,
            keywords: null,
            loading: false,
            items: {
                docs: [],
                pagination: {
                    page: 1
                }
            },
            new_drawer: {
                on: false
            }
        }
    },
    created () {
        this.getItems()
    },
    watch: {
        on (newVal) {
            this.local_on = newVal
            this.getItems()
        },
        local_on (newVal) {
            this.$emit('update:on', newVal)
        },
        'keywords' () {
            this.getItems()
        }
    },
    methods: {
        async getItems (page = 1) {
            this.loading = true
            const filters = {
                agent: this.$tfork.auth.me.id
            }

            if (this.keywords) {
                filters.keywords = this.keywords
            }

            try {
                this.items = await this.$tfork.customers.list({
                    limit: 20,
                    page: page,
                    sort: { business_name: 1 },
                    filters: filters
                })
            } catch (err) {
            } finally {
                this.loading = false
            }
        },
        async select (item) {
            const doc = await this.$tfork.customers.doc(item.id)
            this.$emit('select', JSON.parse(JSON.stringify(doc)))
        }
    }
}
</script>