import Resource from './BaseResource'

class Me extends Resource {
    constructor(config, tools, version, path) {
        super(config, tools, version, path)
    }

    async get() {
        const res = await this.tools.http.get(`${this.api}`)

        if (res) for (const k in res) this[k] = res[k]

        return res
    }

    async getOrders(q) {
        const res = await this.tools.http.get(`${this.api}/orders`, q)
        return res
    }

    async getOrder(id, q) {
        const res = await this.tools.http.get(`${this.api}/orders/${id}`, q)
        return res
    }
}

export default Me
